window.addEventListener('load', function () {

    var organizationProperty = new Vue({
        el: '#formAccidentInsurance',
        data: {
            sent: false,
            errors: '',
            formName: {
                name: 'formAccidentInsurance',
                description: 'Заявка на коллективное страхование сотрудников компании от несчастных случаев'
            },
            form: {
                organizationName: {
                    "f-label": "Наименование компании *", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": true,
                    "f-style": {}
                },
                delegateName: {
                    "f-label": "ФИО представителя",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                phone: {
                    "f-label": "Введите номер телефона для обратной связи *",
                    "f-type": "phone",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {},
                    "f-regexp": "^\\+7\\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$"
                },
                email: {
                    "f-label": "Введите эл.почту для обратной связи *",
                    "f-type": "email",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {}
                },
                organizationKind: {
                    "f-label": "Вид производственной деятельности", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                employeesCnt: {
                    "f-label": "Общее количество сотрудников (чел)", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                singleInsuranceSum: {
                    "f-label": "Страховая сумма для 1 сотрудника (тыс. руб)", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                risk: {
                    "f-label": "Застрахованные риски",
                    "f-type": "multiselect",
                    "f-condition": true,
                    "f-value": [],
                    "f-dic": "riskDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                paymentType: {
                    "f-label": "Тип выплат",
                    "f-type": "select",
                    "f-condition": false,
                    "f-value": "",
                    "f-dic": "paymentTypeDic",
                    "f-plain-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                disabilityDayLabel: {
                    "f-type": "label",
                    "f-condition": false,
                    "f-value": "ограничение по выплате не более 90 дней по 1 страховому случаю и не более 180 дней за срок страхования (1 год)",
                    "f-required": false,
                    "f-style": {}
                },
                disabilityDayLimit: {
                    "f-label": "Лимит выплаты за 1 день нетрудоспособности (% от страховой суммы)",
                    "f-type": "select",
                    "f-condition": false,
                    "f-value": "",
                    "f-dic": "disabilityDayLimitDic",
                    "f-plain-value": "",
                    "f-required": false,
                    "f-style": {}
                }
            },
            dictionaries: {
                riskDic: [
                    {name: "temp-disability", description: "временная нетрудоспособность"},
                    {name: "full-disability", description: "постоянная утрата нетрудоспособности (инвалидность)"},
                    {name: "death", description: "cмерть в результате несчастного случая"}
                ],
                paymentTypeDic: [
                    {name: "accident-table", description: "выплата по таблице травм"},
                    {name: "disability-day", description: "выплата за каждый день нетрудоспособности"}
                ],
                disabilityDayLimitDic: [
                    {name: "one", description: "0,1%"},
                    {name: "three", description: "0,3%"},
                    {name: "five", description: "0,5%"}
                ]
            }
        },
        methods: {
            refreshForm() {
                for (var key in this.form) {
                    this.form[key]['f-value'] = '';
                }
            },
            onSubmit(evt) {
                evt.preventDefault();

                this.errors = "";
                let errorsCnt = validateForm(this.form);
                if (errorsCnt > 0) {
                    this.errors = "Необходимо корректно заполнить все поля, отмеченные звездочкой (*)";
                }

                if (errorsCnt == 0) {

                    let mailMessage = constructMailMessage(this.form, this.formName.description);

                    const emailOptions = {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        data: {msg: mailMessage},
                        url: '/local/form/submit-msg-form.php',
                    };

                    this.$axios(emailOptions)
                        .then(res => { 
                            // console.log('msg was sent ' + mailMessage);
                            this.sent = true; 
                            this.refreshForm();
                            $.fancybox.close({src: '#' + this.form.name});
                            $.fancybox.open('<div  id="thanks" class="popup ths"> <div class="ths-box"> <div class="ths-marg"> <p class="ths-t1">заявка принята</p> <p class="ths-t2">Мы свяжемся с вами <br>в ближайшее время</p> </div> </div> </div>');
                        })
                        .catch(function (error) {
                            console.log(error);
                            $.fancybox.open('<div class="message"><h2>Ошибка</h2></div>');
                        });
                }
            }            
        },
        computed: {
            watchRisk() {
                return this.form.risk['f-value'];
            },
            watchPaymentType() {
                return this.form.paymentType['f-value'];
            },
            watchDisabilityDayLimit() {
                return this.form.disabilityDayLimit['f-value'];
            }
        },
        watch: {
            watchRisk: function() {
                var vm = this;
                try {
                    this.form.paymentType['f-value'] = '';
                    if ('' !== this.form.risk['f-value']) {
                        var showPaymentType = false;
                        for (let riskIdx in this.form.risk['f-value']) {
                            if (showPaymentType) continue;
                            if ('temp-disability' === this.form.risk['f-value'][riskIdx].name) {
                                showPaymentType = true;
                            }
                        }
                        this.form.paymentType['f-condition'] = showPaymentType;
                    }
                    
                    this.form.risk['f-plain-value'] = this.form.risk['f-value'] ? 
                            this.form.risk['f-value'].map(function(bOption) {
                                var dicOption = vm.dictionaries.riskDic.find(function(dic){
                                    return dic.name === bOption.name;
                                });
                                return dicOption.description;
                            }).join('; ') : '';
                } catch(ignoredException) {}
            },
            watchPaymentType: function() {
                var vm = this;
                
                this.form.disabilityDayLimit['f-value'] = '';
                this.form.disabilityDayLimit['f-condition'] = this.form.paymentType['f-value'] === 'disability-day';
                this.form.disabilityDayLabel['f-condition'] = this.form.paymentType['f-value'] === 'disability-day';
                try {
                    this.form.paymentType['f-plain-value'] = this.dictionaries[this.form.paymentType['f-dic']] ? 
                        this.dictionaries[this.form.paymentType['f-dic']].find(function(dic){
                            return dic.name === vm.form.paymentType['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchDisabilityDayLimit: function() {
                var vm = this;
                try {
                    this.form.disabilityDayLimit['f-plain-value'] = this.dictionaries[this.form.disabilityDayLimit['f-dic']] ? 
                        this.dictionaries[this.form.disabilityDayLimit['f-dic']].find(function(dic){
                            return dic.name === vm.form.disabilityDayLimit['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            }
        }
    });
})